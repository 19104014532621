// Drawers

.drawer-content-wrapper {
  z-index: 3000;
  margin-top: 60px;
  background: $white;
  position: relative;
  padding: $layout-spacer-x;
  user-select: text;
  &.overflow-hidden {
    overflow: hidden !important;
  }
  .drawer-heading {
    font-size: $font-size-sm;
    color: $primary-color;
    margin: 0;
    background: $gray-100;
    padding: ($layout-spacer-x / 2) $layout-spacer-x;
    border-bottom: $gray-300 solid 1px;
    border-top: $gray-300 solid 1px;
    font-weight: 600;
    &:first-child {
      border-top: 0;
    }
  }
  .drawer-section {
    padding: $layout-spacer-x;
    overflow: auto;
    max-height: calc(100vh - 15vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
  }
  .drawer-info {
    padding: ($layout-spacer-x/2) 24px;
    // height: 100%;
    &-content-wrapper {
      padding-bottom: 80px;
    }
    .badge {
      font-size: 100%;
    }
    .score {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      &-average {
        font-size: 40px;
        margin-right: 5px;
        color: $orange;
      }
      &-good {
        font-size: 40px;
        margin-right: 5px;
        color: $yellow;
      }
      &-excellent {
        font-size: 40px;
        margin-right: 5px;
        color: $green;
      }
      &-max {
        color: $gray-700;
        font-size: 20px;
      }
    }
  }
  & > .app-sidebar__heading:first-child {
    margin-top: 0;
  }
}

.drawer-nav-btn {
  @include border-radius(30px);
  background: $white;
  position: fixed;
  right: $layout-spacer-x / 2;
  top: 18px;
  padding: ($layout-spacer-x / 3);
  box-shadow: $box-shadow-sm;
  z-index: 20;
  .hamburger {
    margin-top: 4px;
  }
}

.drawer-back-btn {
  @include border-radius(30px);
  background: $white;
  position: fixed;
  right: $layout-spacer-x / 2;
  // left: $layout-spacer-x / 0.5;
  top: 18px;
  padding: ($layout-spacer-x / 3);
  box-shadow: $box-shadow-sm;
  z-index: 20;
  .btn svg {
    color: $primary;
  }
}

.bordered-bottom {
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem !important;
}
.custom-button-status-save {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 6px;
  background-color: $primary;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
}
.full-width {
  width: 100%;
}
.drawer-status-icon {
  font-size: 30px;
}

.drawer-alert {
  display: none;
}

@include media-breakpoint-down(sm) {
  .drawer-content-wrapper .drawer-section {
    max-height: 70vh;
  }
  .drawer-alert {
    display: block;
    text-align: center;
    font-style: italic;
  }
  .custom-button-status-save {
    display: none;
  }
  .drawer-container {
    & > div,
    .drawer-content-wrapper {
      // overflow: auto;
      width: 100% !important;
      max-width: 100% !important;
    }
    .drawer-wrapper-email,
    .drawer-wrapper-status {
      display: none;
    }
  }
}

.drawer-btn-container {
  // margin between children elements
  & > *:not(:last-child) {
    margin-right: $layout-spacer-x;
  }
}
