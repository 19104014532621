.sweet-title {
  font-size: 1.5rem !important;
  font-weight: normal !important;
  margin-bottom: 1rem !important;
}

.sweet-btn {
  margin: 0.2rem !important;
  min-width: 65px;
}

.swal2-loader {
  border-color: $primary transparent $primary transparent !important;
}
