.multiposter-container {
  padding-bottom: 60px;
}

.channel-wrapper {
  font-family: new-kansas, sans-serif;

  display: flex;
  background-color: #ffffff;
  padding: 30px;
  margin: 10px;
  border: 1px solid #f4f4f4;
  .channel-logo-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    img {
      max-width: 160px;
      margin-right: 40px;
    }
  }
  .channel-matching-score {
    color: $green;
  }
}
.channels-totals-wrapper {
  background-color: #fff;
  padding: 24px;
  margin: 60px 10px 10px;
  border: 1px solid #f4f4f4;
}
.channel-total-wrapper {
  position: relative;
  .channel-total-remove {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.channels-total-price-wrapper {
  .channels-total-price {
    font-size: 20px;
    line-height: 24px;
    color: $green;
    font-weight: 600;
    margin-left: 10px;
  }
}
.channel-description {
  overflow: hidden;
  line-height: 1.2em;
  height: 3.6em;
  &.collapsed {
    overflow: hidden;
  }
  &.expanded {
    overflow: visible;
    height: auto;
  }
  &-button {
    background: 0;
    border: 0;
    //   text-decoration: underline;
    font-style: italic;
  }
}
.channel-tabs-wrapper {
  span {
    opacity: 0.5;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    cursor: pointer;
    &.active {
      opacity: 1;
      border-bottom: 2px solid #111;
    }
  }
  margin-bottom: 20px;
}
.multiposter-wrapper {
  h5 {
    font-size: 20px;
    font-weight: 600;
  }
  .channel-total-remove {
    cursor: pointer;
  }
  .selected-channels-title {
    font-size: 18px;
    font-weight: 600;
    padding-right: 20px;
  }
  .channel-info-wrapper {
    flex: 1;
  }
  .selected-channels-wrapper {
    margin-bottom: 20px;
  }
}
.campaign-details-wrapper {
  .select {
    &--is-disabled {
      display: inline-block;
    }
    &__control {
      &--is-disabled {
        background: 0;
        border: 0;
      }
    }
    &__indicators {
      display: none;
    }
    &__multi-value__remove {
      display: none;
    }
    &__multi-value__label {
      padding: 6px;
    }
  }
}
.sticky-selected {
  top: 80px !important;
}
.multiposter-buttons {
  width: calc(100% - 280px);
  position: fixed;
  left: 280px;
  bottom: 0;
  background: #fff;
  display: block;
  z-index: 9;
  padding: 20px 60px;

  @media screen and (max-width: 1266px) {
    width: calc(100% - 80px);
    left: 80px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    left: 0;
  }
}
.multiposter-mobile-message {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.react-datepicker__input-container input {
  width: 100%;
  align-items: center;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 8px;
}
.popup-overlay {
  background: rgba($black, 0.2);
}
.popup-content {
  width: 80%;
  max-height: 90%;
  overflow: auto;
  .campaign-popup {
    background-color: #fff;
    padding: 0 40px;
    .campaign-popup-header {
      padding: 25px 0;
      border-bottom: rgba(#1b1d1f, 0.1) 1px solid;
      h4 {
        color: #1b1d1f;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
      }
    }
    .campaign-popup-content {
      padding: 40px 0;
      &-item {
        padding: 32px 40px;
        border: rgba(#1b1d1f, 0.1) 1px solid;
        @media screen and (max-width: 991px) {
          margin-bottom: 40px;
        }
        &-icon {
          margin-bottom: 40px;
        }
        &-title {
          h5 {
            color: #1c1c1c;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
          }
        }
        &-description {
        }
        &-button {
        }
      }
    }
    .campaign-popup-footer {
      padding-bottom: 60px;
      text-align: center;
    }
    .btn {
      &.btn-primary {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        padding: 13px 40px;
      }
    }
    p {
      color: #52585e;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
    }
    .campaign-popup-back {
      button {
        border: 0;
        background: 0;
      }
    }
    .hubspot-form-wrapper {
      form {
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.hubspot-form-wrapper {
  form {
    fieldset {
      max-width: 100%;
    }
    p {
      color: #52585e;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }
    .field {
      margin-bottom: 20px;
    }
    input:not([type='radio']):not([type='checkbox']):not([type='submit']),
    select,
    textarea {
      width: 100%;
      align-items: center;
      border-color: #ccc;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      transition: all 100ms;
      box-sizing: border-box;
      padding-left: 8px;
    }
    .hs-submit {
      button,
      input[type='submit'] {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        padding: 13px 40px;
        color: #fff;
        background-color: #1d41c8;
        border-color: #1d41c8;
        &:hover {
          background-color: #1836a7;
          border-color: #17339b;
        }
      }
    }
    .no-list {
      margin: 0;
      padding: 0;
      list-style: none;
      &.hs-error-msgs {
        li {
          color: $red;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      input[type='radio'] {
        margin-right: 8px;
      }
    }
  }
}

.select-input {
  z-index: 99999;
}
