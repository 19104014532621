#formBuilder {
  display: flex;
  text-align: left;
  width: 100%;
  margin: 40px auto;

  #formBuilder-form {
    width: 70%;
    border: 1px solid $accent;
    padding-bottom: 150px;
  }
  #optionColumn {
    width: 30%;
    border: 1px solid #000;
  }
  .field {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px;
    color: #ffffff;
    background: darken($color: $accent, $amount: 20);

    border-bottom: 1px solid #ffffff;
  }
  .field.actualmoving {
    background: $accent;
  }
  #formBuilder-form > div > div {
    background: #f9fdff;
  }
  #formBuilder-form > div {
    border-bottom: $accent 2px solid;
  }
  #formBuilder-form > div > div {
    padding: 10px;
  }
  #formBuilder-form .actuallyMoving {
    height: 0;
    padding: 0;
    overflow: hidden;
  }
  .placeholder-down {
    padding-bottom: 20px;
    background: darken($color: $accent, $amount: 20);
  }
  .placeholder-up {
    padding-top: 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$accent+0,7db9e8+100&1+0,0+100;Blue+to+Transparent */
    background: darken($color: $accent, $amount: 20);
  }
  .placeholder {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$accent+0,7db9e8+100&1+0,0+100;Blue+to+Transparent */
    background: darken($color: $accent, $amount: 20);
  }
  #formBuilder-form > div.actualmoving > div {
    /*transform:translateX(-9999px)*/
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$accent+0,00BFF3+50,207cca+51,7db9e8+100;Blue+Gloss+Default */
    background: $accent; /* Old browsers */
    background: darken($color: $accent, $amount: 20);

    color: #ffffff;
  }
  .elementOFDrag input {
    border: 0px;
    border-bottom: 1px solid #000000;
    background: #fff;
    box-shadow: none;
    padding: 8px;
    margin-left: 20px;
  }
  .elementOFDrag textarea {
    border: 0px;
    border-bottom: 1px solid #000000;
    background: #fff;
    box-shadow: none;
    padding: 8px;
    width: 60%;
    margin-left: 20px;
  }
  .mainLabel span {
    display: inline-block;
    width: 20%;
    box-sizing: border-box;
  }
  .inputLabel input,
  .mainLabel select,
  .mainLabel option {
    width: 60%;
    line-height: 20px;
    margin-left: 0;
    border-radius: 0px;
  }
  .mainLabel span {
    vertical-align: top;
    display: inline-block;
    margin-top: 14px;
  }
  label {
    display: block;
  }
  .mainLabel input[type='checkbox'] {
    width: auto;
    height: 15px;
    vertical-align: bottom;
    margin-left: 0;
  }
  .mainLabel select {
    height: 36px;
    line-height: 36px;
    width: calc(60% + 16px);
  }
  .fieldsTop > div,
  .fieldsBottom > div {
    margin: 10px;
  }
  .show {
    display: block;
  }
  .hidden {
    display: none;
  }
  .headerBar {
    padding-bottom: 13px;
    line-height: 30px;
  }
  mainField {
    border-top: 1px solid #82b4ea;
  }
  .headerBar .requied {
    color: red;
    font-size: 25px;
  }
  .headerBar button {
    float: right;
  }
  .headerBar button,
  .optionFields > button {
    background: $accent;
    display: inline-block;
    padding: 8px 13px;
    border-radius: 35px;
    color: #ffffff;
    border: 1px solid $accent;
    margin: 0 5px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    -webkit-transition: all 100ms linear;
    -moz-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    -o-transition: all 100ms linear;
    transition: all 100ms linear;
  }
  .headerBar button:hover,
  .optionFields > button:hover {
    color: $accent;
    background: #ffffff;
  }
  .button-show-more {
    border-top: 1px solid #ccc;
    text-align: center;
    margin-top: 20px;
    position: relative;
    padding-bottom: 20px;
  }
  .button-show-more button {
    display: inline-block;
    background: #f9fdff;
    color: #000000;
    border: 0;
    position: absolute;
    text-shadow: none;
    margin-top: -7px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    width: 110px;
    left: calc(50% - 55px);
    right: calc(50% - 55px);
  }
  .typeField {
    vertical-align: bottom;
    font-size: 14px;
    color: #cccccc;
  }
  .optionFields {
    width: 100%;
    display: inline-block;
  }
  .optionFields ol {
    padding-left: 10px;
  }
  .optionFields ol input:first-child {
    margin-left: 0;
  }
  .optionFields ol li {
    margin-bottom: 7px;
    display: flex;
    flex-flow: row wrap;
  }
  .optionFields ol input {
    width: 50%;
    margin: 0;
  }
  .optionFields ol input:first-of-type {
    width: 100%;
  }
  .optionFields ol input:last-of-type {
    margin-left: 5px;
    width: calc(50% - 5px);
  }
  .optionFields ol button {
    background: $accent;
    display: inline-block;
    padding: 7px 11px;
    border-radius: 5px;
    color: #ffffff;
    border: 1px solid $accent;
    margin: 0 5px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    -webkit-transition: all 100ms linear;
    -o-transition: all 100ms linear;
    transition: all 100ms linear;
    line-height: 15px;
  }
  .optionFields ol button:hover {
    background: #ffffff;
    color: $accent;
  }
  .hideExtraOptions {
    padding-bottom: 20px;
  }
  .fieldToDrag i {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    line-height: 15px;
  }
  pre,
  form {
    text-align: left;
    border: 1px solid #000000;
    margin: 0 auto;
    padding: 20px;
  }
  #formshower h3 {
    height: 30px;
  }
  #formshower {
    display: flex;
    width: 80%;
    margin: 0 auto;
  }
  #formshower > div {
    width: 50%;
  }
}
