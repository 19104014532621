.table-container {
  thead {
    background-color: #f9fafb;
  }

  th {
    font-weight: normal;
    font-size: 16px;
  }
}
