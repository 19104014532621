.company-wizard{
    max-width: 60%;
    display: flex;
    flex-flow: column;
    height: 100vh;
    margin: 0 auto;
    justify-content: center;
    @media screen and (max-width:1200px){
        max-width: 80%;
       
    }
    @media screen and (max-width:767px){
        height: auto;
        padding-top: 30px;
    }
    .form-wizard-step{
        @media screen and (max-width:767px){
            &-todo{
                display: none;
            }
            &-done{
                display: none;
            }
        }
       
    }
}
#root{

    .custom-color-picker{
        display: block;
        span{
            width: 60px;
            height: 40px;
        }

    }
}