.medium-editor-toolbar li button:hover {
  color: $primary !important;
}
.medium-editor-toolbar-form-row {
  margin-left: 50px;
}
.medium-editor-toolbar-form {
  border-radius: 10px !important;
}
div.medium-editor-placeholder,
div.medium-editor-element {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  @include font-size(16);
  line-height: 32px;
}

.vacancy-pages.app-main__inner {
  background-color: #f4f8fe;
  .container {
    position: relative;
  }
  .filters-link {
    color: $primary;
    font-size: 0.88rem;
  }
  .bullet-point-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .remove-icon {
    display: block;
    padding: 8px;
    background: red;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
  }
  .remove-header {
    cursor: pointer;
    font-weight: 600;
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
  .info-bar {
    justify-content: space-between;
    align-items: center;
    .settings-open-wrapper {
      padding: 10px;
      position: relative;
      z-index: 10;
      cursor: pointer;
    }
  }
  .modal-settings {
    position: fixed;
    border: 1px solid lightgrey;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -45%);
    height: calc(100% - 15%);
    width: 800px;
    max-width: 100%;
    transition: 1.1s ease-out;
    z-index: 10;
    background-color: white;
    .modal_title {
      border-bottom: 1px solid lightgrey;
      padding: 24px 40px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .modal_body {
      padding: 24px 40px 0px 40px;
    }
    .modal_footer {
      border-top: 1px solid lightgrey;
      padding: 24px 40px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .btn-sitenu--accent {
    p {
      color: $white;
      font-weight: 600;
      margin: 0;
    }
    // &:hover p {
    //     color: $accent;
    // }
  }
  .new_card {
    position: relative;
    z-index: 0;
    background-color: white;
    padding: 24px;
    margin: 24px 0;
  }
  .hover-section {
    position: relative;
    z-index: 0;
    background-color: white;
    margin: 24px 0px;
    .control-bar {
      visibility: visible;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      z-index: 20;
      width: 100%;
      border-bottom: 1px solid lightgrey;
      padding: 16px 12px;
      cursor: pointer;
      .d-flex {
        align-items: center;
        flex-wrap: wrap;
      }
      .block {
        padding: 0 23px;
        &:not(:last-child) {
          border-right: 2px solid #f3f3f3;
        }
      }
      .dropdown-item {
        &:hover,
        &:focus {
          background-color: #f4f8fe;
        }
      }
      .spacing-dropdown,
      .classes-dropdown {
        position: absolute;
        background-color: $white;
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        .col {
          width: 50px;
          height: 50px;
        }
      }
      .classes-dropdown {
        right: 0;
      }
      .btn-light.no-style {
        background-color: transparent;
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }
    .add-divider {
      visibility: hidden;
      border-left: 2px solid $primary;
      height: 100%;
      position: absolute;
      top: 0;
      margin-left: 10px;
      // .line {
      //     height:100%;
      //     border-left: 3px solid $primary;
      //     position: absolute;
      //     right: 0;
      // }
      .btn-add {
        position: absolute;
        left: -20px;
        top: calc(50% - 20px);
      }
    }
    .btn-custom.btn-danger {
      visibility: hidden;
      position: absolute;
      right: 0;
    }
    .btn-editor {
      visibility: hidden;
      position: absolute;
      &.btn-danger {
        top: 0;
        right: 0;
      }
      &.btn-remove {
        top: 38px;
        left: 52px;
      }
      &.btn-move-left {
        top: 0;
        left: 0;
      }
      &.btn-move-right {
        top: 0;
        left: 36px;
      }
    }
    &:hover,
    &:focus {
      .control-bar {
        visibility: visible;
      }
      .add-divider {
        visibility: visible;
      }
      .btn-editor,
      .btn-custom {
        visibility: visible;
      }
    }
  }
  .slick-slider .slick-dots button {
    background: transparent;
    margin-top: 20px;
    &::before {
      background: $accent;
    }
  }
  .iconPlaceholder {
    border: 1px dashed #d9d9dc;
    background-color: #f9f9fc;
    padding: 21px;
    width: 90px;
    height: 90px;
    max-width: 100%;
    text-align: center;
  }

  .fa-picker {
    max-width: 100%;
    width: 90px;
  }
  a:not(.hamburger-inner),
  span:not(.hamburger-inner),
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    min-height: 10px;
  }
  a:not(.hamburger-inner),
  span:not(.hamburger-inner) {
    display: inline-block;
  }
  .grid-boxes {
    .fa-picker {
      margin: 0 auto;
    }
    .boxes {
      & > div:not(:last-child) > .add-divider {
        display: none;
      }
      .add-divider {
        right: 0;
        margin-right: -15px;
      }
      .box {
        margin: 5px;
      }
    }
  }
  .box {
    position: relative;
    &.onboarding-process-item {
      border: 1px dashed lightgrey;
      max-width: 100%;
      .fa-picker {
        margin: 0 auto;
      }
    }
    .iconPlaceholder {
      margin: 0 auto;
    }
  }
  .switch {
    margin: 10px;
    label {
      margin-bottom: 0;
    }
  }
  .text-2-columns-element,
  .image-3-row-element {
    .row > div:not(:last-child) > .add-divider {
      display: none;
    }
    .add-divider {
      right: 0;
    }
  }
  .image-3-row-element {
    .btn-editor.btn-danger {
      right: 15px;
    }
    .row {
      position: relative;
    }
    .add-divider {
      margin-left: 0;
    }
    .imagePreview {
      border: 1px dashed #d9d9dc;
      background-color: #f9f9fc;
      max-width: 100%;
      min-height: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
      .imagePlaceholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .image-3-grid-element {
    .image {
      position: relative;
      border: 1px dashed #d9d9dc;
      background-color: #f9f9fc;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .form-group {
        text-align: center;
      }
      .imagePlaceholder {
        display: flex;
        flex-direction: column;
      }
      img {
        max-width: 100%;
      }
    }
  }
  .picture_text_element {
    .imagePreview {
      border: 1px dashed #d9d9dc;
      background-color: #f9f9fc;
      min-height: 450px;
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .imagePlaceholder {
        display: flex;
        flex-direction: column;
      }
    }
    img {
      width: 500px;
      max-width: 100%;
    }
  }
  .recruiter-block {
    .contact {
      .contact-icon {
        background-color: #ededf1;
        border-radius: 50%;
        padding: 8px 12px;
        @include font-size(16);
      }
    }
  }
  &.general-pages {
    .add-element {
      position: relative;
      text-align: center;
      cursor: pointer;
      visibility: visible;
      .divider {
        height: 2px;
        position: absolute;
        background-color: #ededf1;
        width: 100%;
        max-width: 100%;
        z-index: 2;
      }
      .divider_button {
        visibility: hidden;
        .btn-add {
          z-index: 3;
          position: relative;
          text-align: center;
        }
      }
      &:hover {
        .divider_button {
          visibility: visible;
        }
        .divider {
          background-color: $primary;
        }
      }
    }
    .elements_block {
      background-color: #1b1d1f;
      position: relative;
      .btn-close {
        float: right;
        margin-top: 10px;
        opacity: 20%;
      }
      .elements_list {
        display: flex;
        flex-wrap: wrap;
        padding: 60px 60px;
        .element {
          padding: 15px;
          margin: 15px;
          background-color: $white;
          border-radius: 16px;
          text-align: center;
          cursor: pointer;
          &.hover {
            background-color: #d1d9f4;
          }
        }
      }
    }
  }
  .highlighted_vacancies_element {
    .btn-sitenu {
      display: inline-block;
    }
  }
  @include media-breakpoint-down(md) {
    .modal-settings {
      left: 50%;
      max-height: 80%;
      overflow-y: auto;
    }
    .info-bar {
      flex-direction: column;
      align-items: flex-start;
      .info-bar_buttons {
        display: flex;
        align-items: normal;
        flex-flow: row wrap;
        button {
          margin: 10px 0;
        }
      }
    }
    .control-bar {
      .right .block {
        padding: 10px;
      }
    }
  }

  // Don't forget to switch from one to another, if you're live or in development
  @import url('http://127.0.0.1:8000/css/dashboard-vacancy-page.css');
  // @import url("/css/dashboard-vacancy-page.css");

  .integration-wrapper {
    .integration-header-wrapper {
      .integration-logo-wrapper {
        img {
          max-width: 160px;
        }
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      margin-bottom: 20px;
    }
    label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }
    input {
      margin-bottom: 16px;
    }
  }
}

.icon-filter-wrapper {
  display: flex;
}

.icon-filter-wrapper > div:not(:last-child) {
  margin-right: 10px;
}
