.change-position-modal {
  padding: 30px;
  h3 {
    margin: 20px 0;
  }
  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.background-image-wrapper {
  display: flex;
  align-items: center;
  img {
    width: 60px;
    height: 40px;
    border: 1px solid #999;
    border-radius: 2px;
    margin-right: 15px;
  }
  .remove-image {
    width: 32px;
    position: absolute;
    top: -14px;
    left: 50px;
    display: none;
  }
  &:hover {
    .remove-image {
      display: block;
    }
  }
}

// GDPR & scripts
@include media-breakpoint-down(sm) {
  .bar_wrapper {
    flex-direction: column;
    .text_wrapper {
      width: 100% !important;
    }
    .gdpr_buttons_wrapper {
      width: 100% !important;
      padding: 0px;
      .button_wrapper {
        width: auto;
        text-align: left !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .gdpr_buttons_wrapper {
    flex-direction: column;
    .button_wrapper {
      width: auto;
      padding: 10px;
    }
  }
}

.section-label {
  font-weight: 600;
  font-size: 16px;
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  align-items: flex-start;
}

.cover-photo-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.cover-photo-wrapper {
  grid-column: span 3 / span 3;
}

.cover-photo-border {
  display: flex;
  justify-content: center;
  max-width: 32rem;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  border: 2px dashed #e6e6e6;
  border-radius: 2px;
}

.cover-photo-inner {
  text-align: center;
  & > * + * {
    margin-top: 0.25rem;
  }

  &--svg {
    width: 3rem;
    height: 3rem;
    color: #94a3b8;
    margin-left: auto;
    margin-right: auto;
  }
}

.cover-photo-content {
  display: flex;
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.25rem;

  & label {
    position: relative;
    background-color: #ffffff;
    color: $primary;
    font-weight: 500;
    border-radius: 0.375rem;
    cursor: pointer;
  }
}
