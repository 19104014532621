/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

// BOOTSTRAP 4 VARIABLES

@import 'components/bootstrap4/functions';
@import 'components/bootstrap4/variables';
@import 'components/bootstrap4/mixins';

// ArchitectUI Themes Variables

@import 'themes/layout-variables';

// BOOTSTRAP 4 IMPORTS

@import 'components/bootstrap4/root';
@import 'components/bootstrap4/reboot';
@import 'components/bootstrap4/type';
@import 'components/bootstrap4/images';
@import 'components/bootstrap4/code';
@import 'components/bootstrap4/grid';
@import 'components/bootstrap4/tables';
@import 'components/bootstrap4/forms';
@import 'components/bootstrap4/buttons';
@import 'components/bootstrap4/transitions';
@import 'components/bootstrap4/dropdown';
@import 'components/bootstrap4/button-group';
@import 'components/bootstrap4/input-group';
@import 'components/bootstrap4/custom-forms';
@import 'components/bootstrap4/nav';
@import 'components/bootstrap4/navbar';
@import 'components/bootstrap4/card';
@import 'components/bootstrap4/breadcrumb';
@import 'components/bootstrap4/pagination';
@import 'components/bootstrap4/badge';
@import 'components/bootstrap4/jumbotron';
@import 'components/bootstrap4/alert';
@import 'components/bootstrap4/progress';
@import 'components/bootstrap4/media';
@import 'components/bootstrap4/list-group';
@import 'components/bootstrap4/close';
@import 'components/bootstrap4/toasts';
@import 'components/bootstrap4/modal';
@import 'components/bootstrap4/tooltip';
@import 'components/bootstrap4/popover';
@import 'components/bootstrap4/carousel';
@import 'components/bootstrap4/spinners';
@import 'components/bootstrap4/utilities';
@import 'components/bootstrap4/print';
// LAYOUT

@import 'layout/layout';

// UTILS

@import 'utils/helpers';
@import 'utils/backgrounds';
@import 'utils/animate';
@import 'utils/colors';
@import 'utils/comps-animations';

// Elements

@import 'elements/buttons';
@import 'elements/badges';
@import 'elements/form-builder';
@import 'elements/loaders';
@import 'elements/dropdown';
@import 'elements/navs';
@import 'elements/forms-wizard';
@import 'elements/pagination';
@import 'elements/table';
@import 'elements/cards';
@import 'elements/sweetalert';

// DASHBOARD BOXES
@import 'widgets/chart-boxes/chart-boxes';
@import 'widgets/content-boxes/content-boxes';
@import 'widgets/profile-boxes/profile-boxes';

// Codemirror
// @import '~codemirror/lib/codemirror.css';
// @import '~codemirror/theme/material.css';

// Applications

@import 'applications/applications-base';

// COMPONENTS

// Perfect Scrollbar
@import 'components/perfect-scrollbar/perfect-scrollbar';

// RC Tabs
@import 'components/rc-tabs/rc-tabs';

// Loading Buttons
@import 'components/loading-buttons/loading-buttons';

// Hamburger Button
@import 'components/hamburgers/hamburgers';

// Drawer
@import 'components/drawer/drawer';

// Drawer
@import 'components/company-wizard/company-wizard';

// Notifications
@import 'components/notifications/sweetalerts';
@import 'components/notifications/toastify';

// Modals
@import 'components/modals/rodal';

// Progress Bar
@import 'components/progress-bar/progress-bar';

// Slick Carousel
@import 'components/slick-carousel/slick/slick';

// Block loading
@import 'components/block-loading/block-loading';

// Popovers & Tooltips
@import 'components/popovers-tooltips/popovers-tooltips';

// Sticky Elements
@import 'components/sticky-elements/sticky-elements';

// Calendar
@import 'components/calendar/calendar';

// Splitters
@import 'components/splitters/splitters';

// Tree View
@import 'components/tree-view/tree-view';

// Tables
@import 'components/tables/tables';

// Flow
@import 'components/flow/flow';

// Icons
@import 'components/icons/linearicons';
@import 'components/icons/p7icons';

// Signature
@import 'components/signature/signature';

// FORMS

@import 'components/forms/datepicker/datepicker';
@import 'components/forms/rangeslider/rangeslider';
@import 'components/forms/multiselect/multiselect';
@import 'components/forms/wysiwyg/wysiwyg';
@import 'components/forms/toggle-switch/switch';
@import 'components/forms/dropzone/dropzone';
@import 'components/forms/typeahead/typeahead';

// REACT WIDGETS

@import 'components/react-widgets/scss/react-widgets';

// DEMO

@import 'demo-ui/demo';

// Responsive

@import 'layout/responsive/responsive-base';

// pages
@import 'pages/userpages';
@import 'pages/vacancy-pages';
@import 'pages/settings-pages';
@import 'pages/unauthorized-pages';
@import 'pages/multiposter';

body {
  font-family: $font-family-body !important;
}

.vertical-nav-menu
  .metismenu-container
  .metismenu-container
  .metismenu-item
  > .metismenu-link {
  padding: 0;
}

.vertical-nav-menu
  .metismenu-container
  .metismenu-container.visible
  .metismenu-item
  > .metismenu-link {
  padding-left: 1em !important;
  padding: 2px 25px 2px 40px;
}

.pagination {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  border-top-width: 1px;
  border-color: #e5e7eb;
  margin-top: 24px;

  @media (min-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }

  &-prev {
    display: flex;
    margin-top: -1px;

    > button {
      display: inline-flex;
      padding-right: 0.25rem;
      padding-top: 1rem;
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      align-items: center;
      border-top-width: 2px;
      border-color: transparent;
      background-color: transparent;

      &:disabled,
      &[disabled] {
        color: #6b7280;
        border-color: #d1d5db;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
      }

      &:hover {
        color: #374151;
        border-color: #d1d5db;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
      }
    }
  }
  &-numbers {
    @media (min-width: 768px) {
      display: flex;
      margin-top: -1px;
    }
    display: none;
    cursor: pointer;
  }
  &-button {
    display: inline-flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    align-items: center;
    border-top-width: 2px;
    border-color: transparent;
    background-color: transparent;

    &:disabled,
    &[disabled] {
      color: #6b7280;
      border-color: #d1d5db;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      border: none;
    }

    &:hover {
      color: #374151;
      border-color: #d1d5db;
    }
  }
  &-next {
    display: flex;
    margin-top: -1px;

    > button {
      display: inline-flex;
      padding-left: 0.25rem;
      padding-top: 1rem;
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      align-items: center;
      border-top-width: 2px;
      border-color: transparent;
      background-color: transparent;

      &:disabled,
      &[disabled] {
        color: #6b7280;
        border-color: #d1d5db;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
  }

  &-icon--next {
    margin-left: 0.75rem;
    color: #9ca3af;
    width: 1.25rem;
    height: 1.25rem;
  }
  &-icon--prev {
    margin-right: 0.75rem;
    color: #9ca3af;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.custom-scroll {
  padding-bottom: 250px;
}
