.loader-wrapper{
    height:400px;
}

.switch-wrapper{
    display: flex;
    flex-flow: row wrap;
    .single-switch-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        h2 {
            display: inline-block;
            font-size: 1.5em;
            word-break: break-word;
            width: calc(100% - 60px);
            @media screen and (max-width:767px){
                display: block;
                font-size: 1.2em;
            }
        }
        .icon-status{
            min-width:30px;
            margin-right: 15px;
            @media screen and (max-width:767px){
                display: none;
            }
        }
        .user-options{
            display: flex;
            min-width: 50px;
            align-self: center;
            padding-left: 5px;
            div{
                cursor: pointer;
                svg{
                    font-size: 16px;
                }
            }
            .status--edit-icon{
                margin-right: 15px;
            }
        }
    }
}
