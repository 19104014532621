// Theme White

// Content

.app-theme-white {
  &.app-container {
    background-color: $gray-50;
  }

  .app-sidebar {
    background-color: $white;
  }

  .app-page-title {
    background-color: rgba(255, 255, 255, 0.55);
  }

  .app-footer .app-footer__inner,
  .app-header {
    background-color: $white;
  }

  &.fixed-header {
    .app-header__logo {
      background-color: rgba($white, 0.1);
    }
  }
}
