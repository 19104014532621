.slick-slider{
    .slider-content{
        h3{
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            margin-bottom: 15px;
        }
        p{
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            opacity: 1;
        }
    }
}