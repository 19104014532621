.signature {
  max-width:500px; 
  border-collapse:collapse;
  td {
    border-top: none;
    padding-left: 0;
  }
  .border-top {
    border-top: 1px #aeb1a6 dotted; 
    padding-top: 15px;
  }
  .logo {
    padding: 20px; 
    width: 100px;
    img {
      max-width: 100px;
    }
  }
  .intro {
    .name {
      font-weight: 800;
      font-size: 18px;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .letters {
    line-height:18px; 
    font-size: 11px; 
    font-weight: 900;
  }
  .horizontal-line {
    width: 40px;
  }
  .details {
    line-height: 18px; 
    text-decoration:none; 
    a {
      font-size: 11px; 
      font-weight: 500; 
      &:hover {
        color: inherit;
      }
    }
  }
  .social {
    padding-bottom: 30px;
  }
}

.footnote {
  font-size: 11px;
}